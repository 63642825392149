import React, { Component } from "react";
import ScoreSideBySide from "./images/ScoreSideBySide.png";
import DefaultGame from "./images/DefaultGame.png";
import StatsHorizontal from "./images/StatsHorizontal.png";
import Calendar from "./images/Calendar.png";
import TeamPage from "./images/TeamPage.png";
import UserManagement from "./images/UserManagement.png";

class StaticPages extends Component {
  salesPitch = () => {
    return (
      <div key="salesPitch">
        <h1 className="setupAppHeader">
          Welcome to Eric's Gear Scoreboard - online!
        </h1>
        <h2 className="setupAppSubHeader">
          This is an online score tracking system. If you keep track of scores,
          this is the score storing and sharing solution for your team.
        </h2>

        <img
          src={ScoreSideBySide}
          className="imageShortFloatRight"
          alt="Scores Side By Side"
        />

        <p>
          This system includes a mobile app that allows you to keep track of the
          current score while you are watching a game. As you make updates, it
          automatically saves the changes to your account here. Not only does
          this allow you to keep accurate track of scores, but it also allows
          you to keep statistics more easily. If other people have the mobile
          app, they can connect to the same game you are scoring to see the
          score, too. This can be done by people in the stands with you or in
          an office miles away.
        </p>

        <img
          src={DefaultGame}
          className="imageShortFloatLeft"
          alt="Default Game"
        />

        <p>
          Choose from a variety of sports that have specific settings for them,
          or just use the "default" game type for sports that aren't listed. If
          your team's sport isn't listed, request it to be added by contacting
          <a href="mailto:sales@ericsgear.com">sales@ericsgear.com</a>.
        </p>

        <img
          src={StatsHorizontal}
          className="imageShortFloatRight"
          alt="Stats"
        />

        <p>
          If your sport has specific stats, like baseball/softball or football,
          you can keep track of them as well. This is part of selecting the
          correct sport for your team.
        </p>

        <img
          src={Calendar}
          className="imageShortFloatLeft"
          alt="Calendar of Events"
        />

        <p>
          The website allows you to keep track of the games that are scheduled,
          as well as schedule your own games. The app allows you to create games
          "on the fly", but the calendar of events allows you to set them up in
          advance.
        </p>

        <img src={TeamPage} className="imageTallRight" alt="Team Page" />

        <p>
          This website also allows you to set up all the team details, such as
          the team name, school, coach, colors, and more. This helps others find
          the team and follow the games and progress through the season. The
          app also allows you to set this up, and setting it up in one place
          shows up everywhere else you would expect it to.
        </p>

        <img
          src={UserManagement}
          className="imageShortFloatLeft"
          alt="User Management"
        />

        <p>
          Since other people can follow a team, if you are a coach or
          scorekeeper for a team, you will be able to manage who can do what.
          This includes setting people as coaches, scorekeepers, or even
          blocking people from seeing your team's scores.
        </p>

        <h2>Pricing</h2>

        <p>
          The price for using this service is $25 per month. It doesn't matter
          how many teams you are on, follow, coach, or whatever or even how often
          they all play, or how many devices you have it on. It's all the same price.
        </p>
      </div>
    );
  };

  render() {
    switch (this.props.page) {
      case "salesPitch":
        return this.salesPitch();
      default:
        return this.salesPitch();
    }
  }
}

export default StaticPages;
